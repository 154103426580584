import { tmrResourceFormController } from 'tmr/controllers/resourceForm/resourceForm'
import templateUrl from './form.html'

/**
 * Zone add/edit form component.
 *
 * @type {ngComponent}
 */
export const tmrAdminCustomZoneForm = {
  ngType: 'component',
  templateUrl,
  controller: 'tmrAdminZoneFormController',
  bindings: {
    resource: '<',
    toast: '<',
  },
}

/**
 * Controller of component {@link tmrAdminZoneForm}.
 *
 * @type {ngController}
 */
export class tmrAdminZoneFormController extends tmrResourceFormController {
  constructor(
    $scope,
    $state,
    $stateParams,
    TmrInvalidFormError,
    TmrToast,
    tmrZoneTypes,
    Place,
    GenericTank,
    Departement
  ) {
    'ngInject'
    super($scope, $state, $stateParams, TmrInvalidFormError, TmrToast)
    Object.assign(this, { tmrZoneTypes, Place, GenericTank, Departement })
  }

  getPlaces(value) {
    return this.Place.search({ value }).$promise
  }

  getHUItems(value) {
    return this.GenericTank.search({ value }).$promise
  }

  getDepartements(departementName) {
    return this.Departement.advancedSearch({ departementName }).$promise
  }
}
