import { constant as defaultApiEndpoints } from 'tmr/constants/defaultApiEndpoints'

export const constant = [
  'tmrDefaultApiEndpoints',
  {
    ...defaultApiEndpoints[1],
    Zone: 'custom/zones/:id',
    HUItem: 'custom/huItems/:id',
    GenericTank: 'genericTanks/:id',
    Place: 'custom/places/:id',
    Test: 'registeredtest/:id',
    'AutomaticTTZDestination': 'automaticttzdestination/:id',
    'ZoneWithPropellant': 'custom/zonesWithPropellant/:id',
    'PlaceWithPropellant': 'custom/placesWithPropellant/:id',
    Scanner: 'custom/scanners/:id',
    Movement: 'custom/movements/:id',
    MovementEncoding: 'custom/movements/Encoding/:id',
    MovementInventoryConfirmation: 'custom/movements/InventoryConfirmation/:id',
    MovementShippingInbound: 'custom/movements/IncomingShipping/:id',
    MovementShippingOutbound: 'custom/movements/OutgoingShipping/:id',
    MovementTransferToZone: 'custom/movements/TransferToZone/:id',
    MovementSale: 'custom/movements/Sale/:id',
    MovementReturn: 'custom/movements/Return/:id',
    Transfer: 'custom/itemTransfers/:id',
    Configuration: 'custom/configurations/:id',
    ItemConfiguration: 'custom/itemConfigurations/:id',
    Departement: 'departements/:id',
  },
]
