/**
 * Resource that represents a Departement.
 *
 * @name Departement
 * @class
 * @augments TmrResource
 */
 function DepartementFactory(TmrResource) {
  'ngInject'
  let referencedProperties = []

  return TmrResource('Departement', {}, referencedProperties)

}

export const factory = ['Departement', DepartementFactory]
