import { resourceListBindings } from 'tmr/components/resourceList/resourceListHelper'
import templateUrl from './zones.html'

/**
 * Administer Zones route
 *
 * @namespace routes.AdminZones
 * @type {TmrRoute}
 */
export const route = {
  name: 'admin.zones.list',
  label: 'ADMIN.ZONES.TITLE',
  url: '/zones',
  component: 'tmrAdminZonesOverride',
  weight: 3,
  resourceType: 'Zone',
  resourceList: {
    searchParameters: ['code', 'description', 'place.id', 'zoneType', 'departement.id'],
  },
  resolve: {
    zoneTypes: /*@ngInject*/ tmrZoneTypes => tmrZoneTypes,
  },
}

/**
 * Component for {@link routes.tmrAdminZonesOverride Administer Zones route}
 *
 * @type {ngComponent}
 */
export const tmrAdminZonesOverride = {
  ngType: 'component',
  templateUrl,
  bindings: resourceListBindings({
    zoneTypes: '<',
  }),
}

